<!--
 * @Descripttion: 
 * @FilePath: /azg/src/views/register/store/components/PasswdInput.vue
 * @Author: 张兴业
 * @Date: 2020-12-09 23:56:17
 * @LastEditors: 张兴业
 * @LastEditTime: 2020-12-13 16:35:37
-->

<template>
  <div class="passwd_input">
    <el-form-item label-width="0px" prop="passwd">
      <el-input
        v-model="passwd"
        placeholder="设置密码（6-12位数字或字母）"
        class="code"
        name="passwd"
        :type="show ? 'text' : 'password'"
        @input="change"
      >
        <template slot="append">
          <img
            :src="show ? openEye : closeEye"
            alt=""
            class="eye"
            @click="switchEyeStatus"
          />
        </template>
      </el-input>
    </el-form-item>
  </div>
</template>

<script>
export default {
  props: ["value"],
  data() {
    return {
      passwd: this.value,
      show: false,
      openEye: require("@/assets/register/eye_open.png"),
      closeEye: require("@/assets/register/eye_close.png")
    };
  },
  methods: {
    switchEyeStatus() {
      this.show = !this.show;
    },
    change(value) {
      this.$emit("input", value);
    }
  }
};
</script>

<style lang="less" scoped>
.passwd_input {
  ::v-deep .el-input__inner {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0;
  }
}

::v-deep .el-input-group__append {
  background: white;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0;
  cursor: pointer;
}

.eye {
  width: 24px;
}
</style>
